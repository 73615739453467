import { useField } from 'remix-validated-form';
import { BaseInput, BaseInputProps } from './BaseInput';

type InputProps = {
  name: string;
  formId: string;
} & BaseInputProps;

export function Input(props: InputProps) {
  const { formId, ...restProps } = props;

  const { error, getInputProps } = useField(props.name, {
    formId: formId,
    validationBehavior: {
      initial: 'onSubmit',
      whenTouched: 'onSubmit',
      whenSubmitted: 'onSubmit',
    },
  });

  return (
    <BaseInput {...restProps} error={error} getInputProps={getInputProps} />
  );
}
